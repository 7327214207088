<template>

</template>

<script>
// import useCart from "@/use/cart"
// import router from '@/router/index'

export default {
    setup() {
        // const { cart, getCartItems, prefs, me, user, customer_id, categories, order_id, message } = useCart()

        return { }
    }
}
</script>