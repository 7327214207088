// console.log('main.js ' + new Date().getTime())
// console.log('document…', document.referrer)
import { Vue, createApp, provide, h } from 'vue'
import router from './router'
// import VueMeta from 'vue-meta'
// import Vue from 'vue'
// Vue.use(VueMeta)
// import VueLocalForage from 'vue-localforage'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { DefaultApolloClient, provideApolloClient, useQuery, useResult } from '@vue/apollo-composable'
import App from './App.vue'
import Error from './components/Error.vue'

import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/lib/theme-chalk/display.css';
import { ElLoading, ElNotification } from 'element-plus'
let loading = ElLoading.service({ fullscreen: true })

// import {prefs} from '@/use/cart'
// import 'element-plus/packages/theme-chalk/src/index'
import axios from 'axios'
import VueAxios from 'vue-axios'

const baseUrl = window.location.href.includes('localhost') ? 'http://localhost:8000/' : 'https://planta.com.ua/__b/public/';

axios.defaults.withCredentials = true // automatically send authentication cookie along with every request
// axios.defaults.baseURL = 'http://localhost:8000/'
axios.defaults.baseURL = baseUrl;
// axios.defaults.headers.common['Accept'] = 'application/json'
// axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
window.addEventListener('error', function(e) {
    axios.post('/api/error', { type: 12, error: e.error.message +'; '+ e.error.stack })
})

try {
    if (!document.cookie.includes('ctest=')) document.cookie='ctest=1';
    var sa = [screen.width, screen.height].sort().reverse();
    sa.push(window.devicePixelRatio);
    var a = [ navigator.vendor, navigator.platform, navigator.maxTouchPoints, sa.join('x'), document.referrer, document.cookie.includes('ctest=') ];
    if (document.cookie.includes('ctest=')) axios.post('/api/push', { type: 1, value: a.map(i => i !== void 0 ? i : 'undefined').join('|') })
    else axios.post('/api/error', { type: 9, error: a.map(i => i !== void 0 ? i : 'undefined').join('|') })
}
catch (e) {
    axios.post('/api/error', { type: 11, error: e.toString() })
}

// if (localStorage.getItem('n_bc')) {
if (localStorage.getItem('n_bc') === 'true') {
    let b = [];
    ['screen.width', 'screen.height', 'navigator.vendor', 'navigator.platform', 'navigator.maxTouchPoints'].forEach(i => {
        let e = i.split('.');
        let r = window[e[0]][e[1]] !== void 0 ? window[e[0]][e[1]] : 'undefined';
        // b.push(r)
        axios.post('/api/error', { type: 11, error: e[1]+': '+r })
    })
    let r = window.devicePixelRatio
    axios.post('/api/error', { type: 11, error: 'dpr: ' + (r !== void 0 ? r : 'undefined') })
    let r2 = document.referrer
    axios.post('/api/error', { type: 11, error: 'ref: ' + (r2 !== void 0 ? r2 : 'undefined') })
    axios.post('/api/error', { type: 11, error: 'sa: ' + sa.join('x') })
    if (document.cookie.includes('ctest=')) axios.post('/api/error', { type: 11, error: 'ctest: 1' })
    axios.post('/api/error', { type: 11, error: a.map(i => i !== void 0 ? i : 'undefined').join('|') })
    // axios.post('/api/error', { type: 11, error: b.join('|')+'_b' })
    // axios.post('/api/error', { type: 11, error: a.map((i) => (i !== void 0 ? i : 'undefined')).join(';') })
    // axios.post('/api/error', { type: 11, error: a.map(function(i) { return (i !== void 0) ? i : 'undefined' }).join('|')+'_fn' })
    localStorage.setItem('n_bc', 'false')
}

    // screen.width
    // screen.height
    // window.devicePixelRatio
    // navigator.vendor, navigator.platform, navigator.maxTouchPoints, document.referrer
// import 'element-plus/lib/index.js'

// installElementPlus(app)
// app.mount('#app')
// import installElementPlus from './plugins/element.js'

export const apolloClient = new ApolloClient({
    uri: baseUrl+'graphql',
    credentials: 'include',
    cache: new InMemoryCache()
})
// console.log(apolloClient)
provideApolloClient(apolloClient)
import mainQuery from '@/graphql/main.query.gql'
// const { result, loading, error } = useQuery(mainQuery)
// const tprods = useResult(result, null, data => data.products)
// if(tprods.value) console.log(tprods.value);
// if(loading) console.log('no loading')
// result.value.then(result => console.log(result))
// console.log(result.value)
//
// export async function test() {
//     return await apolloClient.query({
//         query: gql`
//             {
//                 products(status: true) {
//                     id
//                 }
//             }
//         `
//     })
//     // .then(result => result)
// }

// export function test() {
//     return apolloClient.query({
//         query: gql`
//             {
//                 products(status: true) {
//                     id
//                 }
//             }
//         `
//     })
//     .then(result => result)
// }
// import { preData, preOb, preAr } from '@/use/preload'
// console.log(preOb)
// // preData = 'new data'
// preOb.prop = 'new prop'
// preAr[0] = 'el'
// console.log(preOb)

// console.log('before cart import')
import { settings, products, categories, me, user, sources } from '@/use/cart'
// console.log('after cart import')
import { settings_txt } from '@/use/txt'
// import useCart from "@/use/cart"
// const { cart } = useCart()
// const error = ref(false)

apolloClient.query({ query: mainQuery, fetchPolicy: "network-only" }).then(r => {

    loading.close()

    // if (r.data.user) ElNotification({ title: "Admin User", message: r.data.user.email, type: 'info' });
    if (r.data.user) user.value = r.data.user
    else me.value = r.data.me

    settings.value = r.data.settings
    settings_txt.value = r.data.settings
    // console.log(settings_txt.value)
    products.value = r.data.products

    // Vue.prototype.width = window.innerWidth
    // if (localStorage.getItem('cart')) {
    //     JSON.parse(localStorage.getItem('cart')).forEach(function (item, i) {
    //         if (item) cart.items[i] = item // && products.value && products.value.some(p => p.id == item.id)
    //     })
    // }
    categories.value = r.data.categories
    sources.value = r.data.sources
    // console.log(tst)
    // console.log(preData)
    // console.log('bef App')
    const app = createApp({
        // data() {
        //   return {
        //       test: 'testVal'
        //   }
        // },
        setup() {
            provide(DefaultApolloClient, apolloClient)
        },
        provide: {
            baseUrl: baseUrl
        },
        // created() {
        //     console.log('created')
        // },
        render() {
            return h(App)
        },
        // beforeCreate() {
        //     this.prototype.width = window.innerWidth
        // }
    })
    // app.config.globalProperties.$baseUrl = baseUrl

    app.use(router).use(ElementPlus).use(VueAxios, axios).mount('#app')
    // const app = createApp({ /* options */ })
    // console.log(vm.test)

})

.catch(e => {

    // console.log(e) // statusCode ?!
    loading.close()

    createApp({render() {
        return h(Error)
    }}).use(ElementPlus).mount('#app')

    // ElNotification({
    //     title: "Oops!",
    //     message: "Вибачте, щось пішло не так. Спробуйте перезавантажити сторінку через декілька хвилин, очистити куки або інший бразуер. Звертайтеся! 📬eco@planta.com.ua 📞(098) 555 01 22",
    //     type: "error",
    //     duration: 0,
    //     showClose: false
    // })

    console.log('Something went wrong! :( => '+e)

    axios.post('/api/error', {type: 1, error: e.toString()}).then(r => {
        console.log('report sent')
    }).catch(e2 => {
        console.log('couldn\'t send report: ' + e2)
    })

})