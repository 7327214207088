import { provide, ref, reactive, watch, watchEffect } from 'vue'
import { ElMessage, ElNotification } from 'element-plus'
import { txt } from '@/use/txt'
import axios from "axios";

// console.log('cart.js')
const sortOptions = ["default", "new", "price_a", "price_z", "name_a", "name_z"]; // "old",
export const settings = ref(null)
export const products = ref(null)
export const categories = ref(null)
export const sources = ref(null)
export const me = ref(null)
export const user = ref(null)
export const customer_id = ref(localStorage.getItem('customer_id'))

const rate = ref(1)
// rate.value = me.value && me.value.group !== undefined ? me.value.group.rate : 1
watchEffect(() => {
    rate.value = me.value && me.value.group !== undefined ? me.value.group.rate : 1
})
export const group = ref({
    id: 1,
    rate: 1
})
// rate.value = me.value && me.value.group !== undefined ? me.value.group.rate : 1
watchEffect(() => {
    group.value = me.value && me.value.group !== undefined ? me.value.group : {
        id: 1,
        rate: 1
    }
})
watchEffect(() => {
    localStorage.setItem('customer_id', customer_id.value)
})


const langs = [
    // {id: 1, code: 'en', name: 'English'},
    {id: 2, code: 'ru', name: 'Русский', message: 'Язык изменён.'},
    {id: 4, code: 'uk', name: 'Українська', message: 'Мову змінено.'}
]

let prefs_default = {
    lang: 'uk',
    show_as: 'card',
    sort: 'default',
    notifications: true
}
const prefs = localStorage.getItem('prefs') ? reactive(JSON.parse(localStorage.getItem('prefs'))) : reactive(prefs_default)

Object.keys(prefs_default).forEach((i) => {
    if (!prefs[i]) prefs[i] = prefs_default[i]
})

// langs.find(l => navigator.languages.some(i => i.slice(0,2) == l.code)) ?
//     langs.find(l => navigator.languages.some(i => i.slice(0,2) == l.code)).code : 'uk',

export function log_action(params) {
    axios.post('/api/push', params)
}

function message(title, message, type, duration = 4000) {
    ElNotification({
        title: title,
        message: message,
        type: type,
        duration: duration,
        offset: 50,
    })
}


export let items = []
// let initVal = 0


watchEffect(() => {
    localStorage.setItem('prefs', JSON.stringify(prefs))
    let r = langs.find(l => prefs.lang == l.code).id.toString() + (prefs.show_as == 'list' ? '1' : '0') + sortOptions.indexOf(prefs.sort).toString() + (prefs.notifications ? '1' : '0');
    log_action({ type: 5, value: r })
    // log_action({ type: 5, value: Object.values(prefs).join(';') })
})

export function getCartItems() {
    JSON.parse(localStorage.getItem('cart')).forEach(function (item, i) {
        if(item) items[i] = item // && products.value && products.value.some(p => p.id == item.id)
    })
}

// watch(products, () => {
//     if (localStorage.getItem('cart')) getCartItems()
// })
if (localStorage.getItem('cart')) {
    // items = JSON.parse(localStorage.getItem('cart'))
    getCartItems()
}

const ch_order = ref(JSON.parse(localStorage.getItem('ch_order')))
// const order_items = ref(localStorage.getItem('order_items') && localStorage.getItem('order_items').length
//     ? JSON.parse(localStorage.getItem('order_items')) : null)
const order_items = ref(JSON.parse(localStorage.getItem('order_items')))

// if (products.value) {
const cart = reactive({

    items: items,

    discount: function() {
        return group.value.id < 10 && this.discountable_total() >= settings.value.discount_from ? settings.value.discount_val : 0
    },

    credit: function() {
        if (me.value && me.value.group.id < 10 && this.pre_total() >= settings.value.credit_from) {
            if (ch_order.value) return ch_order.value.credit
            else if (me.value.credit && (!user.value || localStorage.getItem('fd') && JSON.parse(localStorage.getItem('fd')).source_id == 1)) return me.value.credit
            return 0
        }
        return 0

    },

    youll_earn_credit: function() {
        return (me.value && me.value.group.id < 10 && this.total() >= settings.value.credit_from)
            ? (this.total() * settings.value.credit_val) : 0
    },

    total: function() {
        return group.value.id < 10
            ? Math.round((this.pre_total() - this.discountable_total()*this.discount() - this.credit())*100)/100
            : this.pre_total()
    },

    pre_total: function() {
        return this.items.reduce(
            (acc, item) => acc + this.price_total(item), 0
        )
    },

    discountable_total: function() {
        return Math.round(this.items.reduce(
            (acc, item) => acc + this.price_discountable(item), 0
        )*100)/100
    },

    pre_discount_total: function() {
        return this.items.reduce(
            (acc, item) => acc + this.price_item_t(item), 0
        )
    },

    q: function() {
        return this.items.reduce(
            (acc, item) => acc + Number(item.q), 0
        )
    },

    isOver: function() {
        return !!this.items.filter(i => i.q > 0 && i.q > this.availQ(i.id)).length
    },

    reduceOvers: function() {
        // let collected = []

        this.items.forEach((i) => {
            let threshold = this.availQ(i.id) > 0 ? this.availQ(i.id) : 0
            // if (collect && i.q > threshold) collected.push({ id: i.id, q: i.q - threshold })
            i.q = i.q > threshold ? threshold : i.q
        })

        // this.items.forEach((i) => i.q = i.q > i.quantity ? i.quantity : i.q)
        message(txt[prefs.lang].cart, txt[prefs.lang].cart_reduced, 'info')
        // if (collect) return collected

    },

    collectOvers: function() {
        let collected = []
        this.items.forEach((i) => {
            let threshold = this.availQ(i.id) > 0 ? this.availQ(i.id) : 0
            // if (i.q > threshold) collected.push({ id: i.id, q: i.q - threshold })
            // if (i.q > threshold) collected.push({ id: i.id, q: i.q })
            if (i.q > threshold) collected.push(i.id+':'+i.q)
        })
        return collected.join(';')
    },

    forecast: function() {

        function getDate(t) {
            function pad(n) { return n < 10 ? '0'+n : n }
            return t.getFullYear() +'-'+ pad(t.getMonth()+1) +'-'+ pad(t.getDate())
        }

        let date = new Date(1000000000000)
        let unk = false

        this.items.forEach((i) => {

            // let product = products.value.find(p => p.id == i.id)
            let product = this.product(i.id)

            if (i.q > 0 && i.q > this.availQ(i.id)) {
                if (product.next_batch) {
                    let last = new Date(product.next_batch.ready_at)
                    if (last.getTime() > date.getTime()) date = last
                }
                else unk = true
            }
        })

        return date.getTime() !== 1000000000000 && !unk ? getDate(date) : txt[prefs.lang].unknown

    },

    emptyCart: function(notify = true) {
        this.items = []
        // localStorage.setItem('cart', JSON.stringify(this.items))
        if (notify && prefs.notifications)
            message(txt[prefs.lang].cart, txt[prefs.lang].cart_was_emptied, 'warning', settings.value.discount_from)
    },

    preventor: function(e) {
        // console.log(e)
        // e.preventDefault()
        // if (e.keyCode) e.preventDefault()
        // defaultPrevented
        if (e.key === "Unidentified" || !e.ctrlKey && !e.metaKey && (e.key === ' ' || (!Number.isInteger(Number(e.key)))
            && !['Enter', 'Backspace', 'Tab', 'Escape', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key))) e.preventDefault() // 229 only in mobile!
        // else {
        //     console.log(e.srcElement.value)
        // }
        // if (['Minus', 'Equal', 'Comma', 'Period', 'KeyE'].includes(e.code)
        //     || (e.keyCode >= 187 && e.keyCode <= 190)) e.preventDefault()
    },

    product: function(id) {
        let product = products.value.find(i => i.id == id)
        if (!product) {
            delete(this.items[id])
            message(txt[prefs.lang].cart, txt[prefs.lang].disc_was_del, 'warning')
        }
        // return product ? product : { id: 0, url: '', quantity: 0, min_rate: 1.1, price: 0 }
        return product ? product : null
    },

    availQ: function(id) {
        // let product = products.value.find(i => i.id == id)
        let product = this.product(id)

        let orderedQ = ch_order.value && order_items.value && order_items.value.find(i => i && i.id == id)
            ? order_items.value.find(i => i && i.id == id).q : 0

        let whs_q = group.value.id >= 10 ? product.whs_q : 0

        return product.quantity + orderedQ - whs_q
    },

    price_impact: function(item) {
        return item.options.filter(i => i.enabled
                && group.value.id < 10 || (group.value.id >= 10 && i.wholesale) ).reduce(
            (acc, i) => acc + i.price_impact, 0
        )
    },

    price_final: function(item) {
        if (user.value && item.sp !== null && item.sp !== undefined) return item.sp

        let product = this.product(item.id)
        let r = product.min_rate >= 1 ? 1
            : product.min_rate >= rate.value ? product.min_rate : rate.value
        let f = Math.round((product.price + this.price_impact(item)) * r * 100)/100

        return product.special_price && product.special_price < f
            ? product.special_price + this.price_impact(item) : f
    },

    price_item_t: function(item) {
        // console.log(item)
        // let product = this.product(item.id)
        return parseInt(item.q) * this.product(item.id).price
        // return Number(item.q) * products.value.find(i => i.id === item.id).price
    },

    price_total: function (item) {
        return Math.round(parseInt(item.q) * this.price_final(item) * 100)/100
    },

    price_discountable: function (item) {
        // let product = products.value.find(i => i.id == item.id)
        let product = this.product(item.id)
        if (product.special_price) return 0
        let r = product.min_rate > 1 ? 0
            : product.min_rate >= rate.value ? product.min_rate : rate.value
        return Math.round(Number(item.q) * (product.price + this.price_impact(item)) * r * 100)/100
    },

})

// if (products.value) {
//     cart.items.forEach((item, i) => {
//         if (!products.value.some(p => p.id == item.id)) {
//             delete(cart.items[i])
//             message(txt[prefs.lang].cart, 'Disc. product has been removed from cart', 'warning')
//         }
//     })
// }

watchEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart.items))
    let r = cart.items.filter(i => i).map(i => { return i.id+':'+i.q }).join(';')
    let scart = localStorage.getItem('scart')
    if (scart != r) log_action({ type: 7, value: r.length ? r : '0' })
    localStorage.setItem('scart', r)
})
// watch(cart.items, () => {
//     if (products.value)
//         localStorage.setItem('cart', JSON.stringify(cart.items))
// })


export default function useCart() {

    // console.log(products.value)

    const addToCart = (product, q = 1) => {
        if (!cart.items[product.id]) {
            if (Number(q) > 0) {
                cart.items[product.id] = {
                    id: product.id,
                    url: product.url,
                    q: parseInt(q),
                    options: JSON.parse(JSON.stringify(product.options)),
                    sp: null
                    // t: function () {
                    //     return this.q * products.value.find(i => i.id === this.id).price
                    // }
                }
                // cart.items[product.id] = Object.assign({
                //     q: Number(q),
                //     t: function () {
                //         return this.q * this.price
                //     }
                // }, JSON.parse(JSON.stringify(product)))

                // cart.items[product.id].options.forEach((item, i) => Object.assign({ new: 'new' }, cart.items[product.id].options[i]))

                if (prefs.notifications) message(txt[prefs.lang].cart, product[prefs.lang].name + ' ' + txt[prefs.lang].added_to_cart, 'success', 1800)
            }
        }
        else cart.items[product.id].q++

    }

    const delFromCart = (id) => {
        delete cart.items[id]
        // let product = products.value.find(i => i.id == id)
        if (prefs.notifications) message(txt[prefs.lang].cart, cart.product(id)[prefs.lang].name + ' ' + txt[prefs.lang].deleted_from_cart, 'warning', 1800)
    }

    return { cart, addToCart, delFromCart, getCartItems, settings, prefs, langs, log_action, message, me, user, customer_id, rate, group, products, categories, sources, sortOptions, ch_order, order_items }
}