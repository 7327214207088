import { createRouter, createWebHistory } from 'vue-router'
import axios from "axios";
// import VueMeta from 'vue-meta'
import { log_action } from '@/use/cart'
// Vue.use(Meta)

const routes = [
    {
        path: '/',
        props: true,
        component: () => import('../components/Index.vue'),
    },
    { path: '/products', redirect: '/products/all' },
    // { path: '/ru/:url', redirect: '/:url' },
    {
      path: '/products/:url',
      props: true,
      component: () => import('../components/Catalog.vue'),
      // meta: {
      //   title: 'Catalog'
      // }
    },
    {
      path: '/cart',
      component: () => import('../components/Cart.vue'),
    },
    {
      path: '/checkout',
      component: () => import('../components/Checkout.vue'),
      meta: {
        requiresAuth: true
      }
    },
    // {
    //     path: '/success',
    // },
    {
      path: '/order/:id/contact',
      component: () => import('../components/Checkout.vue'),
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/product/:url',
      props: true,
      component: () => import('../components/Product.vue'),
    },
    {
        path: '/email_confirmation',
        component: () => import('../components/EmailConfirmation.vue'),
    },    {
        path: '/password_reset',
        component: () => import('../components/PasswordReset.vue'),
    },
    {
      name: 'profile',
      path: '/profile',
      component: () => import('../components/Profile.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/:id',
      component: () => import('../components/Order.vue'),
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
        path: '/contacts',
        component: () => import('../components/Contacts.vue'),
    },
    {
        path: '/reviews',
        component: () => import('../components/Reviews.vue'),
    },
    {
        path: '/:url',
        props: true,
        component: () => import('../components/Page.vue'),
    },

    {
        path: '/uk/:url/:parts*', redirect: to => {
            // console.log(to.params)
            // const { params } = to
            if (to.params.url === 'products') {
                if (to.params.parts) {
                    let end = to.params.parts[to.params.parts.length-1]

                    if (['laundry', 'shampoo', 'lip-balm', 'soap', 'essential-oils', 'all', 'gift-soap', 'food', 'shea-soap', 'dish-wash', 'paper-tape', 'vegan-supplements-iherb', 'accessories', 'cream'].includes(end)) return '/products/'+end // category
                    else if (end === 'fatty-oils') return '/products/food'
                    else return '/product/'+end
                }

                else return '/products/all'
            }
            else { //  if (!to.params.parts)
                switch (to.params.url) {
                    case 'principles': return '/'
                    case 'response': return '/reviews'
                    case 'subscribe': return '/contacts'
                    case 'delivery': return '/'
                    case 'information': return '/contacts'
                    case 'product': return '/discount'
                    case 'account': return '/profile'
                    default: return '/'+to.params.url
                }
            }
        }
    },
    {
        path: '/ru/:url/:parts*', redirect: to => {
            // console.log(to.params)
            // const { params } = to
            if (to.params.url === 'products') {
                if (to.params.parts) {
                    let end = to.params.parts[to.params.parts.length-1]

                    if (['laundry', 'shampoo', 'lip-balm', 'soap', 'essential-oils', 'all', 'gift-soap', 'food', 'shea-soap', 'dish-wash', 'paper-tape', 'vegan-supplements-iherb', 'accessories', 'cream'].includes(end)) return '/products/'+end // category
                    else if (end === 'fatty-oils') return '/products/food'
                    else return '/product/'+end
                }

                else return '/products/all'
            }
            else { //  if (!to.params.parts)
                switch(to.params.url) {
                    case 'principles': return '/'
                    case 'response': return '/reviews'
                    case 'subscribe': return '/contacts'
                    case 'delivery': return '/'
                    case 'information': return '/contacts'
                    case 'product': return '/discount'
                    case 'account': return '/profile'
                    default: return '/'+to.params.url
                }
            }
        }
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition
    else return { left: 0, top: 0 }
  }
})

// router.beforeEach((to, from, next) => {
//     console.log(to)
// //     // if (to.query.smt !== undefined)
// //     //     next({ path: to.path })
// })

router.afterEach((to, from, next) => {
    // console.log(to.query['utm_source'])
    let path_only = ['/email_confirmation', '/password_reset']
    let path = path_only.includes(to.path) ? to.path : to.fullPath
    log_action({ type: 2, value: path })
    // console.log('after log_action')
    let list = ['fbclid', 'gclid', 'yclid', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'su']
    list.forEach(i => {
        // console.log('clids', i)
        if (to.query[i] !== undefined) history.replaceState(null, '', to.path) // console.log('yes', i) // router.replace({ path: to.path }) // fbclid
    })
    // console.log('afterEach end')
})
// Middleware
// router.beforeEach((to, from, next) => {
//   // console.log('from: '+from.path)
//   // console.log('to: '+to.path)
//   // if (to.meta.requiresAuth) {
//   if (to.matched.some(r => r.meta.requiresAuth)) {
//     if (!me.value) {
//       next({ path: "/login" })
//       // next({ path: "/login", query: { redirect: to.path } })
//     }
//     else next()
//   }
//   // else if (from.path == '/login') console.log('from login')
//   // else if (from.path == '/login' && from.query.redirect) {
//   //   console.log('from login to ' + from.query.redirect)
//   //   router.replace(from.query.redirect)
//   // }
//   else next()
// })

export default router
